@import '../../../../scss/theme-bootstrap';

.hero-block-text-only  {
  &.default-margin {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__content {
    height: 100%;
    justify-content: space-between;
    .content-block__content {
      height: auto;
    }
  }
  &__content-wrapper {
    position: relative;
    height: auto;
  }
  &__wrapper {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  &__text-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
  }
  &__content-background {
    z-index: -1;
  }

  .pc-hidden {
    display: block;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .mobile-flex-hidden {
    display: none;
    @include breakpoint($landscape-up) {
      display: flex;
    }
  }
  .text-cta {
    background: none;
    padding: 0;
    border: none;
    min-width: auto;
  }
  p {
    font-size: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    margin-bottom: inherit;
  }
  // helpers
  .text-color {
    color: $off-black;
    &--white {
      color: $color-white;
    }
    &--black {
      color: $color-black;
    }
    &--light-grey {
      color: $color-light-gray;
    }
    &--mid-grey {
      color: $color-mid-gray;
    }
    &--grey {
      color: $color-mid-gray;
    }
    &--dark-grey {
      color: $color-darker-gray;
    }
    &--green {
      color: $color-green;
    }
    &--light-green {
      color: $color-light-green;
    }
    &--ivory {
      color: $color-ivory;
    }
    &--red {
      color: $color-red;
    }
  }
  // overrides
  div.content-block {
    &__line {
      &--eyebrow {
        .style--bare {
          @include text-style--eyebrow;
        }
      }
      &--header {
        .style--bare {
          @include text-style--large-title-tight;
        }
      }
      &--content {
        .style--bare {
          @include text-style--body;
        }
        &-secondary {
          .style--bare {
            @include text-style--subcopy;
          }
        }
      }
      &--disclaimer {
        .style--bare {
          @include text-style--subcopy;
        }
      }
    }
    &__link {
      padding: 20px 0 0;
      .button {
        &:not([class*='content-block-button--']) {
          @include content-block-btn-primary;
        }
      }
    }
    b,
    strong {
      font-family: $font--futura-demi;
    }
  }
  &.padding-px-150 {
    padding: 150px;
  }
  &.padding-px-200 {
    padding: 200px;
  }
  &.padding-px-300 {
    padding: 300px;
  }
}
